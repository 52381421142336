// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-components-footer-jsx": () => import("./../../../src/pages/components/footer.jsx" /* webpackChunkName: "component---src-pages-components-footer-jsx" */),
  "component---src-pages-components-header-jsx": () => import("./../../../src/pages/components/header.jsx" /* webpackChunkName: "component---src-pages-components-header-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-jsx": () => import("./../../../src/pages/landing.jsx" /* webpackChunkName: "component---src-pages-landing-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */)
}

